import {useMemo} from "react";
import {useANAInfoTracked} from "presentation/store/ANAInfo";
import ANAInfoVM from "presentation/viewModel/ANAInfo/ANAInfoVM/ANAInfoVM";

export const useANAInfoVM = () => {
    const [, setAnaInfoState] = useANAInfoTracked()
    const anaInfoMaintenanceVM = useMemo(() =>
        ANAInfoVM({
            dispatch: [setAnaInfoState],
        }), [setAnaInfoState])
    return anaInfoMaintenanceVM
}