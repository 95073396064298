import { EMPTY_CHARGE_CONFIRMED_MODEL } from 'presentation/model/ChargeConfirmed/ChargeConfirmedModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: ChargeConfirmedProvider,
    useTracked: useChargeConfirmedTracked
} = createContainer(() => useState(EMPTY_CHARGE_CONFIRMED_MODEL), {concurrentMode: true});
export { ChargeConfirmedProvider, useChargeConfirmedTracked };

