import {Permission} from "../../../constant/ANAInfo/PermissionName";
import {FC, useMemo} from "react";
import {useANAInfoTracked} from "../../../store/ANAInfo";
import {useANAInfoRequestRequester} from "../../../hook/ANAInfo/useANAInfoRequestRequester";

type Props = {
    permission: Permission
    children?: React.ReactNode
};

export const ANAInfoChildWrapper: FC<Props> = ({
                                                   permission,
                                                   children
                                               }) => {
    const [anaInfoState] = useANAInfoTracked()
    const memoAllowRead = useMemo(() => anaInfoState.allowRead, [anaInfoState.allowRead])
    useANAInfoRequestRequester(permission)
    return (
        <>
            {memoAllowRead ? children : null}
        </>
    )
}