import _ from "lodash";
import { AclTypeValue, AllNbisivPermission, AttributeTypeValue } from "presentation/constant/ANAInfo/NbisivPermission";

export const isPermissionExist = (permissionName: string, acl: AclTypeValue, currentOperatingCompany: string, allNbisivPermission?: AllNbisivPermission) => {
    if (!allNbisivPermission) {
        return false;
    }
    if (_.isEmpty(allNbisivPermission[permissionName])) {
        return false;
    }
    const isAclExist = allNbisivPermission[permissionName]?.acl?.includes(acl);
    const isTmlExist = allNbisivPermission[permissionName]?.tml?.includes(currentOperatingCompany);
    return isAclExist && isTmlExist;
}

export const retrievePermissionAttributeList = (permissionName: string, attributeName: AttributeTypeValue, allNbisivPermission?: AllNbisivPermission) => {
    if (!allNbisivPermission) {
        return []
    }
    if (_.isEmpty(allNbisivPermission[permissionName])) {
        return []
    }
    const permissionAttributeList = allNbisivPermission[permissionName][attributeName]
    return _.uniq(permissionAttributeList)
}