import { useChargeConfirmedVM } from "presentation/hook/ChargeConfirmed/useChargeConfirmedVM";
import { useChargeConfirmedTracked } from "presentation/store/ChargeConfirmed/ChargeConfirmedProvider";
import { memo, useEffect, useMemo, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ChargeConfirmedTitleBar from "./ChargeConfirmedTitleBar";
import ChargeConfirmedRightTablePanel from "./Table/ChargeConfirmedRightTablePanel";
import ChargeConfirmedTablePanel from "./Table/ChargeConfirmedTablePanel";


const ChargeConfirmedMaintenance:React.FC = () => {
    const [ chargeConfirmedState ] = useChargeConfirmedTracked();
    const { isShowRightPanel } = chargeConfirmedState;
    const chgConfVM = useChargeConfirmedVM();
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const initialScreen = async() => {   
            try {         
                const results = await Promise.allSettled([
                   chgConfVM.onTableInit(),
                   chgConfVM.onSearch(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Table init successful!`);
                    } 
                })
            } catch(error) {
            }
        }
        if(chargeConfirmedState.searchCounter < 1){
            initialScreen().then(() => {  
                setIsLoading(false)
                // chgConfVM.onSearch().then(()=>{
                //     setIsLoading(false)
                // }).catch(error => {            
                //     setIsLoading(false)
                // });
                
            }).catch(error => {            
                setIsLoading(false)
            });
        }
        
    }, [chargeConfirmedState.searchCounter, chgConfVM])

    const memoChargeConfirmedTablePanel = useMemo(() => {return <ChargeConfirmedTablePanel/>}, []);

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className="main-comp-wrapper">
           <ChargeConfirmedTitleBar/>

            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowRightPanel?"40%":"100%"}
                rightSectionWidth={isShowRightPanel?"60%":"0%"}
                leftChildren={memoChargeConfirmedTablePanel} 
                rightChildren={<ChargeConfirmedRightTablePanel/>}
                />
        </div>
    </>
}

export default memo(ChargeConfirmedMaintenance);