import moment from "moment-timezone";

const handleTimeZone = process.env.REACT_APP_HANDLE_TIMEZONE;

export const convertDateLocalFormat = (date:Date) => {
  return date.toLocaleString(
      navigator.languages[0], { 
          timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone, 
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false, }
  )
}
export const convertDateUTCFormat = (date:Date) => {
  if (handleTimeZone) {
    return date.toUTCString();
  } else {
    date.toString();
  }
}

export const convertDateToUTC = (date:moment.Moment) => {
  if (date.isValid()) {
    if (handleTimeZone) {
      const localStr = convertDateLocalFormat(date.toDate());
      let utcMoment = moment(localStr).utc();
      return utcMoment;
    } else {
      return date;
    }
  }
  return null;
}

export const convertDateToLocal = (date:moment.Moment) => {
  if (date.isValid()) {
    if (handleTimeZone) {
      const utcStr = convertDateUTCFormat(date.toDate());
      let localMoment = moment(utcStr).local();
      return localMoment;
    } else {
      return date;
    }
  }
  return null;
}

export const convertDateToDateStr = (date:any) => {
  if (date) {
    return convertDateLocalFormat(date);
  }
}

export const convertDateToDateTimeStr = (date:any) => {    
  if (date) {
    return convertDateLocalFormat(date);
  }
}

export const convertDateToTimeStr = (date:any) => {    
  if (date) {
    return convertDateLocalFormat(date);
  }
}

export const convertDateToDateTimeForComponent = (date:any) => {    
  if (date) {
    const localStr = convertDateLocalFormat(date);
    return moment(localStr).format("DD/MM/YYYY HH:mm");
  }
}

export const convertDateToDateForComponent = (date:any) => {    
  if (date) {
    const localStr = convertDateLocalFormat(date);
    return moment(localStr).format("DD/MM/YYYY");
  }
}
  