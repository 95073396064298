import { NotificationBar } from "veronica-ui-component/dist/component/core";
import React, { useEffect, useState } from "react";
import 'presentation/style/notificationBar.css'
import styled from "styled-components/macro";

const NOTIFICATION_INITIAL_TIME = 20

interface NotificationProps {
    notificationMessage?: string
    isNotificationError: boolean
    onClearNotification: () => void
    isActiveScreen?: boolean
    isWarning?: boolean
    isTime?: number
}

const NotificationWrapper = styled.div`
    .abram-notification-bar{
        z-index: 300000;
    }
`;

export const Notification: React.FC<NotificationProps> = ({
    isTime = NOTIFICATION_INITIAL_TIME,
    notificationMessage,
    isActiveScreen,
    onClearNotification,
    isNotificationError,
    isWarning
}): any => {

    const [time, setTime] = useState(isTime);

    useEffect(() => {
        setTime(isTime)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationMessage])

    return (
        notificationMessage ?
            <NotificationWrapper>
                <NotificationBar
                    id={isActiveScreen ? 'active-notification-bar' : 'workspace-notification-bar'}
                    className={isActiveScreen ? 'active-notification-bar' : 'workspace-notification-bar abram-notification-bar'}
                    width={'100%'}
                    height={'auto'}
                    timer={time}
                    onClear={onClearNotification}
                    text={notificationMessage}
                    background={isNotificationError ? 'Alert Red' : isWarning ? 'Warning Sunset Orange' : 'Success Aqua Green'} />
            </NotificationWrapper> : null
    )
}
    ;
