import {StrictMode} from "react";
import {createRoot} from "react-dom/client";

export const CommonEntrypoint = (renderComponent: JSX.Element, headerComponent: JSX.Element | null = null) => {
    // Bind content
    const appShellId = document.currentScript?.getAttribute("app-name");
    const appShellIdNode = appShellId ? document.getElementById(appShellId) : undefined;
    const root = appShellIdNode ? createRoot(appShellIdNode) : undefined; // createRoot(container!) if you use TypeScript

    // Bind header
    const appHeaderShellId = document.currentScript?.getAttribute("app-name") + '_header';
    const appHeaderShellIdNode = appShellId ? document.getElementById(appHeaderShellId) : undefined;
    const rootHeader = appHeaderShellIdNode ? createRoot(appHeaderShellIdNode) : undefined; // createRoot(container!) if you use TypeScript

    // Current script file
    const currentScriptSrc = document.currentScript?.getAttribute("src");
    console.debug(appShellId);
    console.debug(appShellIdNode);

    // Unmount component
    const unmount = () => {
        root?.unmount();
        rootHeader?.unmount();
    };
    currentScriptSrc && document.addEventListener(`workspace-unmount-${currentScriptSrc}`, unmount, {once: true});

    // Bind modal header
    appHeaderShellId && headerComponent && rootHeader?.render(<StrictMode>{headerComponent}</StrictMode>);

    // Bind modal content
    appShellId && root?.render(<StrictMode>{renderComponent}</StrictMode>);
}