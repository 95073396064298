import { useMessageBarTracked } from "presentation/store/MessageBarProvider";
import { MessageBarVM } from "presentation/viewModel/MessageBarViewModel";
import { useMemo } from "react";

export const useMessageBarVM = () => {
    const [, setMessageBarState] = useMessageBarTracked();
    const messageBarVM = useMemo(() =>
        MessageBarVM({
            dispatch: [setMessageBarState]
        }), [setMessageBarState])

    return messageBarVM;
}