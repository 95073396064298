import { transferRowData } from "presentation/constant/ChargeConfirmed/ChargeConfirmedColumnDefinition";
import { INITIAL_CHARGE_DETAIL_COL_DEF } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailColumnDefinition";
import { INITIAL_CREDIT_NOTE_HEADER_COL_DEF } from "presentation/constant/CreditNote/CreditNoteHeaderColumnDefinition";
import { INITIAL_MAN_CHARGE_HDR_COL_DEF } from "presentation/constant/ManualCharge/ManChargeHdrColumnDefinition";
import { INITIAL_NON_OPS_HDR_COL_DEF } from "presentation/constant/NonOps/NonOpsHdrColumnDefinition";
import { useChargeConfirmedVM } from "presentation/hook/ChargeConfirmed/useChargeConfirmedVM";
import { useChargeConfirmedTracked } from "presentation/store/ChargeConfirmed/ChargeConfirmedProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useEffect, useMemo } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


export const ChargeConfirmedRightTable:React.FC = () => {
    const [ chargeConfirmedState ] = useChargeConfirmedTracked();
    const chgConfVM = useChargeConfirmedVM();

    const { currentRow, detailState }  = chargeConfirmedState;

    const handleClose = useCallback(() => {
        chgConfVM.onCloseDetail();
    }, [chgConfVM]);

    let cols : any[];

    if(currentRow){
        if(currentRow.key === 'com.hph.hpsc.nbis.common.entity.charge.ManualChargeHeaderEntity'){
            cols = currentRow.chargeType === 'NO' ? INITIAL_NON_OPS_HDR_COL_DEF : INITIAL_MAN_CHARGE_HDR_COL_DEF;
        }else if(currentRow.key === 'com.hph.hpsc.nbis.common.entity.charge.CreditChargeHeaderEntity'){
            cols = INITIAL_CREDIT_NOTE_HEADER_COL_DEF;
        }else{
            cols = INITIAL_CHARGE_DETAIL_COL_DEF;
        }
    }else{
        cols = INITIAL_CHARGE_DETAIL_COL_DEF;
    }
    
    useEffect(()=>{
        currentRow && chgConfVM.onDetailSearch(currentRow);
    },[chgConfVM, currentRow])
    
    const memoChgConfRightTable = useMemo(() => {
        return (
        <>    
            <HPHTable
                id='charge-confirmed-detail-list-table'
                headerLabel={'Charge Detail'}
                isNewColumnSetting={true}
                columns={cols.slice()}
                data={transferRowData(detailState.details??[])}
                headerActionButtons={[
                    {
                        id: 'onCloseButton',
                        icon: 'Icon-cross',
                        title: 'Close'
                    }
                ]}
                onCloseButton={handleClose}
                showPaginator={false}
                editable={false}
                showHeaderIcons={true}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)" 
            />
            
        </>
        );
    },[cols, detailState.details, handleClose])


    return <><TableWrapper>{memoChgConfRightTable}</TableWrapper></>;
}

