import { AllNbisivPermission, BuToken, EMPTY_BU_TOKEN } from "presentation/constant/ANAInfo/NbisivPermission"


export interface ANAInfoModel {
    allNbisivPermission?: AllNbisivPermission
    currentBu: string
    availableBuList: string[]
    token?: string,
    buToken?:BuToken,
    allowRead: boolean,
    allowUpdate: boolean,
    allowCreate: boolean,
    allowDelete: boolean,
    email:string,
    defaultEntryPoint: string,
    defaultOperatingCompany: string,
    operatingUnit: string,
    availableOperatingCompanies: string[],
    userName: string,
    country: string,
    timezone: string,
}

export const EMPTY_ANA_INFO_MODEL: ANAInfoModel = {
    allNbisivPermission: {},
    currentBu: "",
    availableBuList: [],
    token: "",
    buToken: EMPTY_BU_TOKEN,
    allowRead: false,
    allowUpdate: false,
    allowCreate: false,
    allowDelete: false,
    email:"",
    defaultEntryPoint: "",
    defaultOperatingCompany: "",
    operatingUnit: "ECT",
    availableOperatingCompanies: [],
    userName: "",
    country:"",
    timezone:"",
}