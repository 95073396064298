export const DynamicColumnNameMapping = {
    ChargeConfirmed: {
        "charge.CHGCOMMACTMAINTDTL.label.billToCompany": "Bill-to Company",
        "document.DOCDOCGEN.label.handlingTerminal": "Handling Tml.",
        "company.COMCUSTENQ.label.customerCode":"Customer Code",    
        "document.DOCDOCGEN.label.customerName1":"Customer Name 1",
        "document.DOCDOCGEN.label.customerName2": "Customer Name 2",
        "document.DOCDOCGEN.label.serviceCode": "Service Code",
        "document.DOCDOCGEN.label.opsDate": "Ops. Date",
        "charge.CHGCHGHDR.label.billingCycle": "Billing Cycle",
        "charge.CHGCHGHDR.label.billingCycleCode": "Billing Cycle Code",
        "charge.CHGCHGHDR.label.OPSDateFrom": "Ops Date From",
        "charge.CHGCHGHDR.label.OPSDateTo": "Ops Date To",
        "general.GENCHGTYPE.label.chargeType": "Charge Type",
        "document.DOCDOCGEN.label.consortiumCode": "Consortium Code",
        "document.DOCDOCGEN.label.vslCode": "Vessel Code",
        "document.DOCDOCGEN.label.voyCode": "Voyage Code",
        "document.DOCDOCGEN.label.depDate": "ETD",
        "document.DOCDOCGEN.label.endVisitInd":"End Visit Ind.",
        "document.DOCDOCGEN.label.endVisitDate":"End Visit Date",
        "document.DOCDOCGEN.label.fullEmpty":"Full/Empty",
        "document.DOCDOCGEN.label.docType": "Doc. Type",
        "document.DOCDOCGEN.label.confirmedBy": "Confirmed by",
        "document.DOCDOCGEN.label.confirmedDate": "Confirmed Date",
        "No. of Charges": "No. of Charges"
    },
    documentPreview: {
        "document.DOCDOCPREVIEW.label.previewRefNo":"Preview Ref. No.",
        "document.DOCDOCPREVIEW.label.docType":"Doc. Type",
        "document.DOCDOCGEN.label.handlingTerminal":"Handling Tml.",
        "document.DOCDOCPREVIEW.label.serviceCode":"Service Code",
        "document.DOCDOCPREVIEW.label.billToCompany":"Bill To Company",
        "document.DOCDOCPREVIEW.label.customerCode":"Customer Code",
        "document.DOCDOCPREVIEW.label.customerDesc1":"Customer Name 1",
        "document.DOCDOCPREVIEW.label.customerDesc2":"Customer Name 2",
        "document.DOCDOCPREVIEW.label.opsDate":"Ops. Date",
        "general.GENCHGTYPE.label.chargeType":"Charge Type",
        "document.DOCDOCPREVIEW.label.consortiumCode":"Consortium Code",
        "document.DOCDOCPREVIEW.label.vslCode":"Vessel Code",
        "document.DOCDOCPREVIEW.label.voyCode":"Voyage Code",
        "document.DOCDOCPREVIEW.label.vslName":"Vessel Name",
        "document.DOCDOCPREVIEW.label.depDate":"Dep Date/Time",
        "document.DOCDOCPREVIEW.label.errorMessage":"Error Message",
        "document.DOCDOCGEN.label.generatedBy":"Generated by",
        "document.DOCDOCGEN.label.generationDate":"Generation Date",
        "document.DOCDOCPREVIEW.label.contractCurrency":"Contract Currency",
        "document.DOCDOCPREVIEW.label.billlingType":"Billing Type",
        "document.DOCDOCPREVIEW.label.billlingCycleCode":"Billing Cycle Code",
        "document.DOCDOCPREVIEW.label.poNo":"P.O. No.",
        "document.DOCDOCENQHDR.label.approvalStatus":"Approval Status",

    }
}

export const getChargeConfirmedValue = (key: keyof typeof DynamicColumnNameMapping.ChargeConfirmed): string | undefined => {
    return DynamicColumnNameMapping.ChargeConfirmed[key];
}
export const getDocumentPreviewValue = (key: keyof typeof DynamicColumnNameMapping.documentPreview): string | undefined => {
    return DynamicColumnNameMapping.documentPreview[key];
}