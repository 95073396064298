
import { DynamicTableEntity } from "domain/entity/Common/DynamicTableEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { DynamicTableRepository } from "./DynamicTableRepo";

export const DynamicTableRepoImpl = (): DynamicTableRepository => {
    const url = '/v1/dynamicTable';

    const getDynamicTableCols = async(screenName: string) : Promise<DynamicTableEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, [screenName]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        })
        
    }


    return {
        getDynamicTableCols: getDynamicTableCols,
    }
}