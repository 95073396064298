import { AxiosInstance } from "axios";
import authAxiosInstance from "./authAxiosInstanc";

export async function axiosPostData(axiosInstance:AxiosInstance, url:string, data:any) : Promise<any> {
    try {
        return axiosInstance.post(url, JSON.stringify(data)).then(res => {
            return res.data;
        }).catch(error => {
            return {status:"500", error: "Failed to fetch data"}
        })
    } catch(error) {
        return {status:"500", error: error};
    }
}

export async function axiosPutData(axiosInstance:AxiosInstance, url:string, data:any) : Promise<any> {
    try {
        return axiosInstance.put(url, JSON.stringify(data)).then(res => {
            return res.data;
        }).catch(error => {
            return {status:"500", error: "Failed to fetch data"}
        })
    } catch(error) {
        return {status:"500", error: error};
    }
}

export async function axiosGetData(axiosInstance:AxiosInstance, url:string, keys?:string[]) : Promise<any> {
    let newUrl = url;
    if (keys && keys.length > 0) {
        keys.map((key) => newUrl = newUrl + "/" + encodeURIComponent(key));
    } 

    try {
        return axiosInstance.get(newUrl).then(res => {
            return res.data;
        }).catch(error => {
            return {status:"500", error: "Failed to fetch data"}
        })
    } catch(error) {
        return {status:"500", error: error};
    }
}

export async function axiosDeleteData(axiosInstance:AxiosInstance, url:string, key:string) : Promise<any> {
    try {
        return axiosInstance.delete(`${url}/${key}`).then(res => {
            return res.data;
        }).catch(error => {
            return {status:"500", error: "Failed to fetch data"}
        })
    } catch(error) {
        return {status:"500", error: error};
    }
}

export async function axiosUploadFile(axiosInstance:AxiosInstance, url:string, file:any) : Promise<any> {
    const formData = new FormData();
    formData.append('file', file);

    try {
        return axiosInstance.postForm(`${url}`, formData).then(res => {
            return res.data;
        }).catch(error => {
            return {status:"500", error: "Failed to fetch data"}
        })
    } catch(error) {
        return {status:"500", error: error};
    }
}


export async function axiosGetDataFromPublicAPI(url:string) : Promise<any> {
    let basicUrl = `/public${url}`;

    try {
        return authAxiosInstance.get(`${basicUrl}`).then(res => {
            return res.data;
        }).catch(error => {
            return {status:"500", error: "Failed to fetch data"}
        })
    } catch(error) {
        return {status:"500", error: error};
    }
}
    