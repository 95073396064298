export const ChargeConfirmedConstant = {    
    Header: {        
        CHG_CONF_LIST: 'Charge Confirmed List',
        BILL_TO: 'Bill-to Company',
        HANDLING_TML: 'Handling Tml',
        CUST_CODE: 'Customer Code',
        CUST_NAME1: 'Customer Name 1',
        CUST_NAME2: 'Customer Name 2',
        SERV_CODE: 'Service Code',
        OPS_DATE: 'Ops. Date',        
        BILL_CYCLE: 'Billing Cycle',
        BILL_CYCLE_CODE: 'Billing Cycle Code',
        OPS_FROM: 'Ops Date From',
        OPS_TO: 'Ops Date To',
        CHG_TYPE: 'Charge Type',
        CO: 'Consortium Code',
        VSL: 'Vessel Code',
        VOY: 'Voyage Code',
        ETD: 'Dep Date/Time',
        END_VISIT_IND: 'End Visit Ind.',
        END_VISIT_DATE: 'End Visit Date',
        FULL_EMPTY: 'Full/Empty',
        DOC_TYPE: 'Doc. Type',
        CONF_BY: 'Confirmed By',
        CONF_DATE: 'Confirmed Date',
    },
    Detail:{
        
    }
}