import {FC} from "react";

type Props = {
    isHide?: boolean
    children?: React.ReactNode
};

export const TableWrapper: FC<Props> = ({
                                            isHide,
                                            children
                                        }) => {
    return (
        <div className={`im-table-wrapper ${isHide ? 'im-table-wrapper-hide' : ''}`}>
            {children}
        </div>
    )
}
