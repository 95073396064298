import { ANAInfo } from "presentation/constant/ANAInfo/ANAInfo";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ANAInfoModel } from "presentation/model/ANAInforModel/ANAInfoModel";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";

interface ANAInfoVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ANAInfoModel>> | ((value: SetStateAction<ANAInfoModel>) => void),
    ]
}

const ANAInfoVM = ({
    dispatch
}: ANAInfoVMProps) => {
    const [anaInfoDispatch] = dispatch  

    const handleANAInfoResponses = (e: Event, permission: Permission) => {
        const anaInfo = (e as CustomEvent<ANAInfo>).detail
        localStorage.setItem('access_token_with_refresh', (e as CustomEvent<ANAInfo>)?.detail?.token ?? '')
        if (permission === Permission.LOGIN) {
            anaInfoDispatch(prevState => {
                return {
                    ...prevState,
                    allNbisivPermission: anaInfo.allNbisivPermission,
                    currentBu: anaInfo.currentBu,
                    token: anaInfo.token,
                    allowRead: !!anaInfo.token,
                    allowUpdate: !!anaInfo.token,
                    allowCreate: !!anaInfo.token,
                    allowDelete: !!anaInfo.token,
                    email: anaInfo.email??"",
                    operatingUnit: anaInfo.operatingUnit??"2691",
                    buToken: anaInfo.buToken,
                    defaultEntryPoint: anaInfo.entryPoint,
                    defaultOperatingCompany: anaInfo.currentOperatingCompany,
                    availableBuList: anaInfo.availableBuList,
                    availableOperatingCompanies: anaInfo.availableOperatingCompanies,
                    userName: anaInfo.userName,
                    country: anaInfo.country,
                    timezone: anaInfo.timezone,
                }
            });          
        } else {
            anaInfoDispatch(prevState => {
                return {
                    ...prevState,
                    allNbisivPermission: anaInfo.allNbisivPermission,
                    currentBu: anaInfo.currentBu,
                    token: anaInfo.token,
                    allowRead: isPermissionExist(permission, AclType.READ, anaInfo.currentOperatingCompany, anaInfo.allNbisivPermission),
                    allowUpdate: isPermissionExist(permission, AclType.UPDATE, anaInfo.currentOperatingCompany, anaInfo.allNbisivPermission),
                    allowCreate: isPermissionExist(permission, AclType.CREATE, anaInfo.currentOperatingCompany, anaInfo.allNbisivPermission),
                    allowDelete: isPermissionExist(permission, AclType.DELETE, anaInfo.currentOperatingCompany, anaInfo.allNbisivPermission),
                    email: anaInfo.email??"",
                    operatingUnit: anaInfo.operatingUnit??"2691",
                    buToken: anaInfo.buToken,
                    defaultEntryPoint: anaInfo.entryPoint,
                    defaultOperatingCompany: anaInfo.currentOperatingCompany,
                    availableBuList: anaInfo.availableBuList,
                    availableOperatingCompanies: anaInfo.availableOperatingCompanies,
                    userName: anaInfo.userName,
                    country: anaInfo.country,
                    timezone: anaInfo.timezone
                }
            })
        }
    };


    return {
        handleANAInfoResponses: handleANAInfoResponses,
    }
}

export default ANAInfoVM