import {FC} from "react";
import { MessageBarChildWrapper } from "./MessageBarChildWrapper";
import { MessageBarProvider } from "presentation/store/MessageBarProvider";

type Props = {
    children?: React.ReactNode
};

export const MessageBarWrapper: FC<Props> = ({ children }) => 
<MessageBarProvider>
    <MessageBarChildWrapper>
        {children}
    </MessageBarChildWrapper>
</MessageBarProvider>;
