import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ChargeConfirmedProvider } from "presentation/store/ChargeConfirmed/ChargeConfirmedProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import ChargeConfirmedMaintenance from "presentation/view/section/ChargeConfirmed/ChargeConfirmedMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ChargeConfirmedContMain = () => {
    return <ANAInfoWrapper permission={Permission.CHARGE_CONFIRMED_MAIN}>
        <MessageBarWrapper>
            <ChargeConfirmedProvider>
                <GridStyles/>
                <ChargeConfirmedMaintenance/>
            </ChargeConfirmedProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default ChargeConfirmedContMain;