import { useChargeConfirmedTracked } from "presentation/store/ChargeConfirmed/ChargeConfirmedProvider";
import { memo } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { ChargeConfirmedRightTable } from "./ChargeConfirmedRightTable";


const ChargeConfirmedRightTablePanel:React.FC = () => {
    const [ chargeConfirmedState ] = useChargeConfirmedTracked();

    const { detailState }  = chargeConfirmedState;


    return (
        <div className='main-comp-wrapper' style={{ height: "calc(100vh)" }}>
            <div className='side-form-content-left-wrapper'style={{padding:"0px"}} >
                {detailState.isFetching && <Loader Indicator="Spinner" size="Medium" />}
                <SliderPanel
                    isOpen={false}
                    leftSectionWidth={'100%'}
                    draggable={true}
                    leftChildren={<ChargeConfirmedRightTable/>}/>
            </div> 
    </div>
    )
}

export default memo(ChargeConfirmedRightTablePanel);
