import { MessageBarModel } from "presentation/model/MessageBar";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "./BaseViewModel";

interface MessageBarVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<MessageBarModel>> | ((value: SetStateAction<MessageBarModel>) => void),
    ]
}

export const MessageBarVM = ({ dispatch,  }: MessageBarVMProps) => {
    const [messageBarDispatch] = dispatch;

    const showError = (message:string) => {
        messageBarDispatch(prevState => ({
            ...prevState,
            errorDuration: 5,
            errorCountDownFlag: 0,
            isOpen: true,
            isNotificationError: true,
            message: message,
            isWarning: false,
        }));
    }

    const showWarining = (message:string) => {
        messageBarDispatch(prevState => ({
            ...prevState,
            errorDuration: 5,
            errorCountDownFlag: 0,
            isOpen: true,
            isNotificationError: false,
            message: message,
            isWarning:true,
        }));
    }
    

    const showClose = () => {
        messageBarDispatch(prevState => ({
            ...prevState,
            isOpen: false,
            message: '',
            errorCountDownFlag: -1,
            errorDuration: 0,
        }));
    }
    

    const showSuccess = (message:string) => {
        messageBarDispatch(prevState => ({
            ...prevState,
            errorDuration: 5,
            errorCountDownFlag: 0,
            isOpen: true,
            isNotificationError: false,
            message: message,
            isWarning: false,
        }));
    }

    return {
        showClose: showClose,
        showWarining: showWarining,
        showError: showError,
        showSuccess: showSuccess
    }
} 