import {useEffect} from "react";
import {useANAInfoVM} from "./useANAInfoVM";
import {Permission} from "../../constant/ANAInfo/PermissionName";

const REQUEST_ANA_INFO_EVENT = 'request-ana-info'
const RESPONSE_ANA_INFO_EVENT = 'response-ana-info'

export const useANAInfoRequestRequester = (permission: Permission) => {
    const anaInfoVM = useANAInfoVM()
    useEffect(() => {
        const handleANAInfoResponses = (e: Event) => {
            anaInfoVM.handleANAInfoResponses(e, permission)
        }
        document.addEventListener(RESPONSE_ANA_INFO_EVENT, handleANAInfoResponses)
        document.dispatchEvent(new CustomEvent(REQUEST_ANA_INFO_EVENT))
        return () => {
            document.removeEventListener(RESPONSE_ANA_INFO_EVENT, handleANAInfoResponses)
        }
    }, [anaInfoVM, permission])
};
