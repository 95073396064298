import {FC} from "react";
import {ANAInfoProvider} from "presentation/store/ANAInfo";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ANAInfoChildWrapper } from "./ANAInfoChildWrapper";

type Props = {
    permission: Permission
    children?: React.ReactNode
};

export const ANAInfoWrapper: FC<Props> = ({ permission, children }) => <ANAInfoProvider><ANAInfoChildWrapper permission={permission}>{children}</ANAInfoChildWrapper></ANAInfoProvider>;
