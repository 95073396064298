export type AclTypeValue = 'C' | 'R' | 'M' | 'D'

export type AclTypeInterface = {
    [key in 'CREATE' | 'READ' | 'UPDATE' | 'DELETE']: AclTypeValue
}
export const AclType: AclTypeInterface = {
    CREATE: 'C',
    READ: 'R',
    UPDATE: 'M',
    DELETE: 'D',
}

export interface NbisivPermission {
    acl: AclTypeValue[],
    tml: string[],

}

export interface AllNbisivPermission {
    [key: string]: NbisivPermission,
}

export interface BuToken {
    operatingUnit?:string,
    operatingCompany?:string,
    defaultEntryPoint?: string,    
}

export const EMPTY_BU_TOKEN:BuToken = {
    operatingCompany:"",
    operatingUnit:"ECT",
    defaultEntryPoint: ""
}

export type AttributeTypeValue = 'tml'

export type AttributeTypeInterface = {
    [key in 'TERMINAL']: AttributeTypeValue
}
export const AttributeType: AttributeTypeInterface = {
    TERMINAL: 'tml',
}