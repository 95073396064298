export const ChargeConstant = {
    Charge: {
        CONTAINER: 'CONTAINER',
        VOYAGE: 'VOYAGE',
        CHARGE: 'CHARGE',
        DOCUMENT: 'DOCUMENT',
        STATE: 'State',
        BILLING_TYPE: 'Billing Type',
        CNTR_NO: 'Container No.',
        SL_VESSEL_CODE: 'S/L Vessel Code',
        SL_IB_VOYAGE_CODE: 'S/L IB Voyage Code',
        SL_OB_VOYAGE_CODE: 'S/L OB Voyage Code',
        PREVIEW_REF_NO: 'Preview Ref. No',
        OPS_DATE: 'Ops Date',
        OPS_DATE_RANGE: 'Ops Date Range',
        CHARGE_TYPE: 'Charge Type',
        SUB_CHARGE_TYPE: 'Sub Charge Type',
        ALTERABLE_CHARGE_ON_COMPANY: 'Alterable Charge-On Company',
        ALTERABLE_BILL_TO_COMPANY: 'Alterable Bill-To Company',
        CHARGE_ON_COMPANY: 'Charge-On Company',
        BILL_TO_COMPANY: 'Bill-To Company',
        CONSORTIUM_CODE: 'Consortium Code',
        VESSEL_CODE: 'Vessel Code',
        VOYAGE_CODE: 'Voyage Code',
        ETD: 'ETD',
        ETD_RANGE: 'ETD Range',
        HANDLING_TML: 'Handling Tml.',
        CONSORTIUM_CODE_ARRIVAL: 'Consortium Code(Arrival)',
        VESSEL_CODE_ARRIVAL: 'Vsl Code(Arrival)',
        VOYAGE_CODE_ARRIVAL: 'Voy Code(Arrival)',
        SERVICE_CODE_ARRIVAL: 'Service Code(Arrival)',
        VESSEL_NAME_ARRIVAL: 'Vessel Name(Arrival)',
        CONSORTIUM_CODE_DEPATURE: 'Consortium Code(Departure)',
        VESSEL_CODE_DEPATURE: 'Vsl Code(Departure)',
        VOYAGE_CODE_DEPATURE: 'Voy Code(Departure)',
        SERVICE_CODE_DEPATURE: 'Service Code(Departure)',
        VESSEL_NAME_DEPATURE: 'Vsl Name(Departure)',
        BUNDLE_IND: 'Bundle Ind.',
        OPERATION_TYPE: 'Operation Type',
        INVOICE_NO: 'Invoice No.',
        INVOICE_DATE: 'Invoice Date',
        INVOICE_DATE_RANGE: 'Invoice Date Range',
        CREDIT_NOTE_NO: 'Credit Note No.',
        CREDIT_NOTE_DATE: 'Credit Note Date',
        DRAFT_INVOICE_NO: 'Draft Invoice No.',
        DRAFT_CREDITE_NOTE_NO: 'Draft Credit Note No.',
        ADJ_TYPE: 'Adj. Type',
        BILLING_CYCLE_CODE: 'Billing Cycle Code',
        SIZE: 'Size',
        TYPE: 'Type',
        TARIFF_CODE: 'Tariff Code',
        TARIFF_CODE_DESC: 'Tariff Code Desc.',
        END_VISIT_IND: 'End Visit Ind.',
        MODALITY_OF_ARRIVAL: 'Modality Of Arrival',
        MODALITY_OF_DEPATURE: 'Modality Of Depature',
        FULL_EMPTY: 'Full/Empty',
        CONTAINER_VISIT: 'Container Visit',
        EXCEPTION_MESSAGE: 'Exception Message',
        OPS_DATE_TIME: 'Ops Date Time',
        GATE_IN: 'Gate-In',
        GATE_IN_RANGE: 'Gate-In Range',
        GATE_OUT: 'Gate-Out',
        GATE_OUT_RANGE: 'Gate-Out Range',
        IN_YARD_DATE: 'In Yard Day',
        CHARGING_QTY_OT_DAYS: 'Charging Qty/OT Days',
        SPECIAL_HANDLING_CODE: 'Sp Code',
        SPECIAL_HANDLING_CODE_D1: 'Sp Code(D1)',
        MANUAL_SYSTEM_IND: 'Manual/System Ind.',
        FROM_TML: 'From Tml.',
        TO_TML: 'To Tml.',
        CO_VSL_VOY: 'Co/Vsl/Voy',
        INV_CRE_NOTE_NO: 'IN/CN Note No.',
        VSL: 'Vsl',
        VOY: 'Voy',
        OVERLENGTH:"Over Length",
        OVERHEIGHT:"Over Height",
        OVERWIDTH:"Over Width",
        INTMLDATETIME:"In Date/Time",
        OUTTMLDATETIME:"Out Date/Time"
      },
    ChargeHeader: {
      CHARGE_PROCESSING: 'Charge Details',
      CHARGE_HEADER: 'Charge Header',
      VOYAGE: 'VOYAGE',
      LOGIN_USER: 'Login User',
      USER: 'User',
      CO_VSL_VOY: 'Co/Vsl/Voy',
      HEADER_STATE: 'State',
      CONSORTIUM_CODE: 'Consortium Code',
      VESSEL_CODE: 'Vessel Code',
      VOYAGE_CODE: 'Voyage Code',
      VESSEL_NAME: 'Vessel Name',
      ETD: 'ETD',
      ETA: 'ETA',
      ETA_RANGE: 'ETA Range',
      FROM: '(from)',
      TO: '(to)',
      SOA: 'SOA',
      OPS_DATE: 'Ops Date',
      CONFIRM_TYPE: 'Confirm Type',
      CHARGE_GROUP: 'Charge Group',
      SHIP_OWNER: 'Ship Owner',
      BILL_TO_COMPANY: 'Bill-to Company',
      GROUP_TYPE: 'Group Type',
      OPERATING_TML: 'Operating Tml',
      BILLING_CYCLE: 'Billing Cycle',
      BILLING_CYCLE_CODE: 'Billing Cycle Code',
      OPS_DATE_FROM: 'Ops Date From',
      OPS_DATE_TO: 'Ops Date To',
      SERVICE_CODE: 'Service Code',
      HANDLING_TML: 'Handling Tml.',
      VESSEL_TYPE: 'Vessel Type',
      DIVERT_SHIP: 'Divert Ship',
      DIVERT_SHIP_FROM: 'Divert Ship From',
      CHARGE_DETAIL_INFO: 'Charge Detail Info',
      SHIP_BOUNDED: 'Ship Bounded',
      SHIP_BOUNDED_PERIOD: 'Ship Bounded In Period',
      DOMESTIC: 'Domestic',
      TO_BILL: 'To Bill',      
      REFRESH_HEADER_STATE: 'Refresh Header State'   
    },
    VesselSummary: {
      VESSEL_SUMMARY: 'Vessel Summary',
      VESSEL_SUMMARY_HEADER: 'Vessel Summary Header',
      TDR: 'Terminal Departure Report',
      VESSEL_TYPE: 'Vessel Type',
      LASHING: 'LASHING',
      GEARBOX: 'GEARBOX',
      ISOHATCH: 'ISOHATCH',
      LATE_ARRIVAL: 'LATE ARRIVAL',
      SHIFTING_B_B: 'SHIFTING B/B',
      RESTOW_B_S_B: 'RESTOW B/S/B',
      HANDLING_TML: 'Handling Tml',
      BILL_TO_COMPANY: 'Bill-to Company',
      SIZE: 'Size',
      SIZE_UNIT: 'F/M',
      //DISCHARGE_LOCAL: 'Discharge Local',
      //DISCHARGE_TRANSHIPMENT: 'Discharge Transhipment',
      DISCHARGE_LOCAL: 'D1 Local',
      DISCHARGE_TRANSHIPMENT: 'D1 TS',
      D1_TOTAL: 'D1 Total',
      D1_CY_OOG: 'D1 CY OOG',
      D1_CY_IMO: 'D1 CY IMO',
      D1_CY_BM: 'D1 CY BM',
      D1_CY_BS: 'D1 CY BS',
      L2_LOCAL: 'L2 Local',
      L2_TS: 'L2 TS',
      L2_TOTAL: 'L2 Total',
      L2_CY_OOG: 'L2 CY OOG',
      L2_CY_IMO: 'L2 CY IMO',
      L2_CY_BM: 'L2 CY BM',
      L2_CY_BS: 'L2 CY BS',
      TOTAL: 'Total',
      PERCENT: 'Percent',
      D1_YH_OOG: 'D1 YH OOG',
      D1_YH_IMO: 'D1 YH IMO',
      D1_YH_BM: 'D1 YH BM',
      D1_YH_BS: 'D1 YH BS',
      DS_FULL: 'Discharge Full',
      DS_EMPTY: 'Discharge Empty',
      LD_FULL: 'Loading Full',
      LD_EMPTY: 'Loading Empty',
      RESTOW: 'RESTOW',
      SHIFT: 'Shift',
      REMARK: 'Remark',
      SIZE_20: '20\'',
      SIZE_40: '40\'',
      SIZE_45: '45\'',
      SIZE_48: '48\'',
      SIZE_53: '53\'',
      IN_NBIS: 'In NBIS',
      DIFFERENT: 'Different',
      DETAIL: 'Show Detail',
    },
    InboundContainerSummary: {
      INBOUND_CONTAINER_SUMMARY: 'Inbound Container Summary',
      VESSEL_TYPE: 'Vessel Type',
      CONSORTIUM_CODE: 'Consortium Code',
      VESSEL_CODE: 'Vessel Code',
      VOYAGE_CODE: 'Voyage Code',
      ETD: 'ETD',
      HANDLING_TML: 'Handling Terminal',
      DELIVERY_STATUS: 'Delivery Status',
      CONTAINER_NO: 'Container No.',
      END_VISIT_IND: 'End Visit Ind.',
      END_VISIT_DATE: 'End Visit Date',
      STATUS: 'Status',
      SIZE: 'Size',
      OPERATING_TML: 'Operating Tml',
      DS_DATETIME: 'Discharge Date/Time',
      DELIVERY_DATETIME: 'Delivery Date/Time',
      COKD_OPSDATE: 'COKD OPS Date',
      STATUS_COKD: 'Status(COKD)',
    },
    DeliveryChargeSummary: {
      DELIVERY_CHARGE_SUMMARY: 'Delivery Charge Summary',
      LOGIN_USER: 'Login User',
      OPS_LINE: 'Ops Line',
      OPERATING_TML: 'Operating Tml',
      HANDLING_TML: 'Handling Tml',
      CONSORTIUM_CODE: 'Consortium Code',
      VESSEL_CODE: 'Vessel Code',
      VOYAGE_CODE: 'Voyage Code',
      VESSEL_NAME: 'Vessel Name',
      ETD: 'ETD',
      ETA: 'ETA',
      SOA: 'SOA',
      SERVICE_CODE: 'Service Code',
      BILLING_CYCLE: 'Billing Cycle',
      BILLING_CYCLE_CODE: 'Billing Cycle Code',
      DISCHARGE: 'Discharge',
      END_VISIT_IND: 'End Visit Ind.',
      BILLED: 'Billed',
      TO_BE_BILLED: 'To Be Billed',
      NON_BILL_ABLE: 'Non Billable',
      NOT_DELIVERY: 'Not Delivery',
      DIFFERENT: 'Different',

      SHOW_DIFFERENCE: 'Show Difference',
    }
}