import { FC, ReactNode, useEffect, useState } from "react";

type Props = {
    children?: React.ReactNode;
    initialComponentsFunction?: (param: any) => Promise<ReactNode>;
    param?: any,
    disabled?:boolean,
};

export const HeaderTitle: FC<Props> = ({ children = <></>, initialComponentsFunction = undefined, param = undefined }) => {    
    const [components, setComponents] = useState<ReactNode>();

    useEffect(() => {
        if (initialComponentsFunction) {
            initialComponentsFunction(param).then(data => {
                if (data) {
                    setComponents(<>{data}</>);
                }
            });
        }
    }, [initialComponentsFunction, param]);

    return <div className='customeTitle'>{children}{components}</div>;
}
