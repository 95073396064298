export const nonOpsChgDtl2dpNumberFieldList: string[] = [
    'chargeAmount',  
    'chargeQty',
];

export const nonOpsChgDtl4dpNumberFieldList: string[] = [
    'individualChargeAmount',
];

export const nonOpsChgDtlPositiveIntegerNumberFieldList: string[] = [
    'vatDays', 
];

export const nonOpsChgDtlUpperCaseRemoveSpaceFieldList: string[] = [
    'revenueAccountCode', 
    'costCenterCode',
    'salesChannelCode',
    'projectCode',
    'spareCode',
    'taxRevenueAccountCode',
    'taxCostCenterCode',
    'taxSalesChannelCode',
    'taxProjectCode',
    'taxSpareCode',
];

export const nonOpsChgDtlUpperCaseFieldList: string[] = [
    'productServiceCode', 
    'taxProductServiceCode',
];

export const nonOpsHdrRequiredFieldList: string[] = [
    'stdBillserviceDate', 
    'billToCompany',  
    'customerCode',
    'hdrDesc1',  
    'hdrDesc2',  
];

export const nonOpsHdrStdBillRequiredFieldList: string[] = [
    ...nonOpsHdrRequiredFieldList,
    'stdBillEffectiveDate', 
    'stdBillBillingCycle',
];

export const nonOpsDtlRequiredFieldList: string[] = [
    'chargeAmount', 
    'individualChargeAmount', 
    'dtlDesc1', 
    'tariffType', 
    'tariffCode',
    'chargeQty',  
    'revenueAccountCode',
    'costCenterCode',
    'productServiceCode',
    'salesChannelCode',
    'projectCode',
    'spareCode',
    'intercompanyCode',
    'vatPercentage',  
    'individualChargeQtyUom',  
    'vatOneOffTaxCode',
    'vatTarType',  
    'vatTarCode',  
    'vatArticleStatementCode',   
    'vatDays',  
    'vatPercentage',  
    'individualChargeQtyUom', 
    'taxRevenueAccountCode',  
    'taxCostCenterCode',
    'taxProductServiceCode',
    'taxSalesChannelCode',
    'taxCountryCode',
    'taxIntercompanyCode',
    'taxProjectCode',
    'taxSpareCode',
];

export const NonOpsConstant = {    
    Header: {        
        NON_OPS_STD_BILL: 'Non-Ops / Standard Bill',
        NON_OPS_TYPE: 'Non-Ops Type',
        NON_OPS: 'Non-Ops',
        REF_NO: 'Ref. No.',
        STATE: 'State',
        ORDER_NO: 'Order No.',
        STD_BILL: 'Std Bill',
        INV_CR_NO: 'IN/CN Note No.',
        BILLING_CYCLE: 'Billing Cycle',
        SERV_DATE: 'Service Date',
        EFF_DATE: 'Effective Date',
        EXP_DATE: 'Expiry Date',
        CUST_CODE: 'Customer Code',
        CUST_NAME: 'Customer Name',
        CURRENCY: 'Currency',
        CHG_DESC: 'Charge Desc',        
        YOUR_REF: 'Your Ref',
        OUR_REF: 'Our Ref',
        REMARK: 'Remarks',
        SUBJECT: 'Subject',
        HDR_DESC1: 'Description 1',
        HDR_DESC2: 'Description 2',
        OPERATING_TML: 'Operating Tml',
        COMPANY_CODE: 'Company Code',
        INV_NO: 'Invoice No.',
        CREDIT_NOTE_NO: 'Credit Note No.',
        DRAFT_INV_NO: 'Draft Invoice No.',
        DRAFT_CREDIT_NOTE_NO: 'Draft Credit Note No.',
    },
    Detail:{
        NON_OPS_DTL_TITLE: 'Detail',
        NON_OPS_DTL: 'Non-Ops Detail',
        AMT: 'Amount',
        UNIT_PRICE: 'Unit Price',
        TAR_TYPE: 'Tariff Type',
        TAR_CODE: 'Tariff Code',
        CHARGE_ON: 'Charge-on Company',
        CHARGE_QTY: 'Charging Qty',
        UOM: 'Charge UOM',
        BTN_GET_UOM: 'Get UOM',
        DTL_DESC1: 'Detail Desc 1',
        DTL_DESC2: 'Detail Desc 2',
        BTN_UPT_COA: 'Update COA',
        BTN_UPT_TAX_COA: 'Update Tax COA',

        REV_AC_CODE: 'Revenue A/C Code',
        COST_CENTER_CODE: 'Cost Center Code',
        PROD_SER_CODE: 'Product Service Code',
        SALES_CHANNEL_CODE: 'Sales Channel Code',
        COUNTRY_CODE: 'Country Code',
        PROJECT_CODE: 'Project Code',
        SPARE_CODE: 'Spare Code',
        INTER_COMPANY_CODE: 'Inter-company Code',
        
        TAX_REV_AC_CODE: 'Tax Revenue A/C Code',
        TAX_COST_CENTER_CODE: 'Tax Cost Center Code',
        TAX_PROD_SER_CODE: 'Tax Product Service Code',
        TAX_SALES_CHANNEL_CODE: 'Tax Sales Channel Code',
        TAX_COUNTRY_CODE: 'Tax Country Code',
        TAX_PROJECT_CODE: 'Tax Project Code',
        TAX_SPARE_CODE: 'Tax Spare Code',
        TAX_COMPANY_CODE: 'Tax Company Code',
        TAX_INTER_COMPANY_CODE: 'Tax Inter-company Code',

        TAX_TAR_TYPE: 'Tax Tariff Type',
        TAX_TAR_CODE: 'Tax Tariff Code',
        TAX_TAR_DESC: 'Tax Tariff Code Desc',
        TAX_CODE: 'Tax Code',
        TAX_PERCENTAGE: 'Tax%',
        TX_TYPE: 'Transaction Type',
        ARTICLE_STATEMENT_CODE: 'Article Statement Code',
        ARTICLE_STATEMENT: 'Article Statement',
        
        TAX_DTL_DESC1: 'Tax Detail Desc 1',
        TAX_DTL_DESC2: 'Tax Detail Desc 2',
        TAX_PAYMENT_TERM: 'Tax Payment Term',
        TAX_PAYMENT_REF_TERM: 'Tax Payment Ref Date',
        REF_NO_ADJ_FROM: 'Ref No Adj From',
        ADJ_TYPE: 'Adj. Type',
        REASON_CODE: 'Reason Code',
        EX_MSG: 'Exception Msg',
    }
}