import { ChargeConstant } from "../ChargeHeader/ChargeConstant";

export const ChargeDetailConstant = {
    Charge: {
        STATE: ChargeConstant.Charge.STATE,
        CNTR_NO: ChargeConstant.Charge.CNTR_NO,
        CHARGE_TYPE: ChargeConstant.Charge.CHARGE_TYPE,
        SUB_CHARGE_TYPE: ChargeConstant.Charge.SUB_CHARGE_TYPE,
        CHARGE_ON_COMPANY: ChargeConstant.Charge.CHARGE_ON_COMPANY,
        BILL_TO_COMPANY: ChargeConstant.Charge.BILL_TO_COMPANY,
        CONSORTIUM_CODE: ChargeConstant.Charge.CONSORTIUM_CODE,
        VESSEL_CODE: ChargeConstant.Charge.VESSEL_CODE,
        VOYAGE_CODE: ChargeConstant.Charge.VOYAGE_CODE,
        ADJ_TYPE: ChargeConstant.Charge.ADJ_TYPE,
        TARIFF_CODE: ChargeConstant.Charge.TARIFF_CODE,
        SIZE: ChargeConstant.Charge.SIZE,
        TYPE: ChargeConstant.Charge.TYPE,
        FULL_EMPTY: ChargeConstant.Charge.FULL_EMPTY,
        BILLING_TYPE: ChargeConstant.Charge.BILLING_TYPE,
        FROM_TML: ChargeConstant.Charge.FROM_TML,
        TO_TML: ChargeConstant.Charge.TO_TML,
        SL_VESSEL_CODE: ChargeConstant.Charge.SL_VESSEL_CODE,
        SL_IB_VOYAGE_CODE: ChargeConstant.Charge.SL_IB_VOYAGE_CODE,
        SL_OB_VOYAGE_CODE: ChargeConstant.Charge.SL_OB_VOYAGE_CODE,
        ALTERABLE_CHARGE_ON_COMPANY: ChargeConstant.Charge.ALTERABLE_CHARGE_ON_COMPANY,
        ALTERABLE_BILL_TO_COMPANY: ChargeConstant.Charge.ALTERABLE_BILL_TO_COMPANY,
        HANDLING_TML: ChargeConstant.Charge.HANDLING_TML,
        CONSORTIUM_CODE_ARRIVAL: ChargeConstant.Charge.CONSORTIUM_CODE_ARRIVAL,
        VESSEL_NAME_ARRIVAL: ChargeConstant.Charge.VESSEL_NAME_ARRIVAL,
        VESSEL_CODE_ARRIVAL: ChargeConstant.Charge.VESSEL_CODE_ARRIVAL,
        VOYAGE_CODE_ARRIVAL: ChargeConstant.Charge.VOYAGE_CODE_ARRIVAL,
        SERVICE_CODE_ARRIVAL: ChargeConstant.Charge.SERVICE_CODE_ARRIVAL,
        CONSORTIUM_CODE_DEPARTURE: ChargeConstant.Charge.CONSORTIUM_CODE_DEPATURE,
        VESSEL_CODE_DEPARTURE: ChargeConstant.Charge.VESSEL_CODE_DEPATURE,
        VOYAGE_CODE_DEPARTURE: ChargeConstant.Charge.VOYAGE_CODE_DEPATURE,
        SERVICE_CODE_DEPARTURE: ChargeConstant.Charge.SERVICE_CODE_DEPATURE,
        VESSEL_NAME_DEPARTURE: ChargeConstant.Charge.VESSEL_NAME_DEPATURE,
        BUNDLE_IND: ChargeConstant.Charge.BUNDLE_IND,
        MODALITY_OF_ARRIVAL: ChargeConstant.Charge.MODALITY_OF_ARRIVAL,
        MODALITY_OF_DEPARTURE: ChargeConstant.Charge.MODALITY_OF_DEPATURE,
        GATE_IN: ChargeConstant.Charge.GATE_IN,
        IN_YARD_DATE: ChargeConstant.Charge.IN_YARD_DATE,
        SPECIAL_HANDLING_CODE: ChargeConstant.Charge.SPECIAL_HANDLING_CODE,
        SPECIAL_HANDLING_CODE_D1: ChargeConstant.Charge.SPECIAL_HANDLING_CODE_D1,
        EXCEPTION_MESSAGE: ChargeConstant.Charge.EXCEPTION_MESSAGE,
        INVOICE_NO: ChargeConstant.Charge.INVOICE_NO,
        DRAFT_INVOICE_NO: ChargeConstant.Charge.DRAFT_INVOICE_NO,
        CREDIT_NOTE_NO: ChargeConstant.Charge.CREDIT_NOTE_NO,
        OPERATION_TYPE: ChargeConstant.Charge.OPERATION_TYPE,
        INV_CRE_NOTE_NO: ChargeConstant.Charge.INV_CRE_NOTE_NO,
        CO_VSL_VOY: ChargeConstant.Charge.CO_VSL_VOY,
        CHARGE: ChargeConstant.Charge.CHARGE,

        TARIFF_TYPE: 'Tariff Type',
        SEQ: 'Seq. No.',
        SERVICE_CODE: 'Service Code',
        OPS_LINE: 'Ops Line',
        FORWARDER_CODE: 'Forwarder Code',
        BUNDLE_NUMBER: 'Bundle Number',
        CHARGE_IND: 'Charge Ind',
        OPERATING_TML: 'Operating Tml',
        POO: 'POO',
        POL: 'POL',
        POD: 'POD',
        FD: 'FD',
        DEPARTURE_DATE_TIME: 'Departure Date/time',
        ARRIVAL_DATE_TIME: 'Arrival Date/time',
        RECEIVE_DATE_TIME: 'Receive Date/time',
        DELIVERY_DATE_TIME: 'Delivery Date/time',
        HANDLING_DATE_TIME: 'Handling Date/time',
        VESSEL_NAME: 'Vessel Name',
        MANUAL_TS_LEG_CHARGE_IND: 'Manual Ts Leg Charge Ind.',
        TS_LEG_CHARGE_IND: 'Ts Leg Charge Ind.',
        CM_AC_P_RATE: 'Cm A/C P.rate',
        BARGE_JOB_ID: 'Barge Job Id',
        VOYAGE_OPERATING_TML: 'Voy. Operating Tml',
        VOYAGE_SERVICE_CODE: 'Voy. Service Code',
        SOA: 'SOA',
        QTY: 'Qty',
        RATE: 'One-off Rate',
        DIRECT_IND: 'Direct Discharge Ind.',
        SPECIAL_IND: 'Special Ind.',
        SPECIAL_HANDLING_IND: 'Special Handling Ind.',
        SPECIAL_HANDLING_IND_D1: 'Special Handling Ind.(D1)',
        EQUIPMENT_CODE: 'Equipment Code',
        IMCO_CODE: 'IMCO Code1',
        IMCO_CODE2: 'IMCO Code2',
        IMCO_CODE3: 'IMCO Code3',
        GROSS_WT: 'Gross Wt',
        DU_CONTENT: 'DU Content',
        DG_CONTENT: 'DG Content',
        VOLTAGE: 'Voltage',
        RF_TEMP: 'RF Temp.',
        RF_TEMP_UNIT: 'RF Temp. Unit',
        PERMIT_NO: 'Permit No.',
        REFERENCE_NO: 'Reference No.',
        LOADING_DATE_TIME: 'Loading Date/time',
        DISCHARGE_DATE_TIME: 'Discharge Date/time',
        ORIG_RECEIVE_DATE_TIME: 'Orig Receive Cutoff Date/time',
        NON_CELLULAR_VESSEL_IND: 'Non-cellular Vessel Ind.',
        WIRE_SLING_HANDLING_IND: 'Wire sling Handling Ind.',
        SHIFT_CODE: 'Shift Code(IS)',
        SURCHARGE_PERCENTAGE: 'Surcharge%',
        SO_NUMBER: 'So Number',
        CARGO_CODE: 'Cargo Code',
        TRAIN_ID: 'Train ID',
        TRACTOR_NO: 'Tractor No.',
        FORWARDER_REFERENCE: 'Forwarder Ref',
        OH_DIMEN: 'OH',
        OL_DIMEN: 'OL',
        OW_DIMEN: 'OW',
        POO_GEOG_IND: 'POO Geog Ind',
        POL_GEOG_IND: 'POL Geog Ind',
        POD_GEOG_IND: 'POD Geog Ind',
        FD_GEOG_IND: 'FD Geog Ind',
        POO_COUNTRY: 'POO Country',
        POL_COUNTRY: 'POL Country',
        POD_COUNTRY: 'POD Country',
        FD_COUNTRY: 'FD Country',
        SHIPMENT_TYPE: 'Shipment Type',
        INVOICE_LEG: 'invoice Leg',
        CM_AC_P_RATE_PERCENTAGE: 'Cm A/C% / P.Rate%',
        OTHER_LEG_TS_LEG_CHARGE_IND: 'Other/TS Leg Charge Ind.',
        REASON_CODE: 'Reason Code',
        DRAFT_CREDIT_NO: 'Draft Credit No.',
        MC_NO: 'Manual Charge No.',
        CR_NO: 'Credit Charge No.',
        OFF_STD_BACK_LENGTH: 'Off Std Back Length',
        OFF_STD_FRONT_LENGTH: 'Off Std Front Length',
        OFF_STD_LEFT_WIDTH: 'Off Std Left Width',
        OFF_STD_RIGHT_WIDTH: 'Off Std Right Width',
        ITEM_DESC: 'Item Desc.',
        GATE_CLOSING_DATE_TIME: 'Gate Closing Date/Time',
        CUSTOMER_SIZE_TYPE: 'Customer Size Type',
        MARSHAL_FROM: 'Marshal From',
        MARSHAL_TO: 'Marshal To',
        OVER_TIME_IND: 'Overtime Ind.',
        OVER_WEIGHT_IND: 'Over Weight Ind.',
        EMPTY_IND: 'Empty Indicator',
        OVER_DIMENSION_IND: 'Over Dimension Ind.',
        EQUIPMENT_CODE_IND: 'equipment Code Ind.',
        PARTNER_CODE: 'Partner Code',
        CNTR_TSP_STATUS: 'Transportation Status',
        OUT_TML_IND: 'Out Terminal Ind.',
        OUT_TML_DATE_TIME: 'Out Terminal Date/Time',
        OFF_STD_MEASUREMENT_MODE: 'Off Std Measurement Mode',
        IGNORE_CNTR_STATUS_VALIDATION: 'Ignore Cont. Status Validation',
        COKD_OPS_DATE_TIME: 'COKD OPS Date',
        COKD_STATUS: 'Status (COKD)',
        COMMON_AC_TYPE: 'Cm A/C Type',
        COMMON_AC_FIX_QTY: 'Cm A/C Fix Qty',
        RE_DIRECT_FROM_TML: 'Re Direct From Tml.',
        LEG_SPECIAL_HANDLING_CODE: 'Leg Sp Code',
        LEG_SPECIAL_HANDLING_IND: 'Leg Sp Ind.',
        REBILL_FROM: 'Re-bill From',
        CNTR_STATUS: 'Cntr Status',
        CHARGE_CODE: 'Charge Code',
        CHANGE_ACTION: 'Change Action',

        CHARGE_TITLE: "Charge",
        CHARGEPROCESSING_TITLE: "Charge Details",
        BULK_EDIT: "Batch Edit",
        ADJUSTMENT_BUT: "Adjustment",
        APPLY_BUT: "Apply",
        APPLY_ALL_BUT: "Apply All",
        CONFIRM_BUT: "Confirm",
        CONFIRM_ALL_BUT: "Confirm All",
        UNCONFIRM_BUT: "Un-Confirm",
        UNCONFIRM_ALL_BUT: "Un-Confirm All",
        DELETE_BUT: "Delete",
        LEGEND_BUT: "Legend",
        SUMMARY_BUT: "Charge Summary",
        SEARCH_CRITERIA: "Search Criteria",
        CNTR_VISIT_ID: ChargeConstant.Charge.CONTAINER_VISIT,
        UPDATED_CNTR_DETAIL_IND: 'Updated Container Detail Indicator',
        UPDATE_CONTAINER_INFO: 'Update Container Info',
        UPDATE_VESSEL_VOYAGE: "Update Vessel Voyage",
        UPDATE_CONTAINER_STATUS: "Update Status",
        ACC_RESUME_TML_BUT: "Acc/Resume Tml",
        HOLD_UNHOLD_BUT: "Hold/Un-Hold",
        RELEASE_MASTER_HOLD_BUT: "Release Master Hold",
        ASS_RESUME_ONEOFF_BUT: "Assign/Resume One-Off Rate",
        UPDATE_VESSEL_VOYAGE_BUT: "Update Vsl/Voy",

        BERTHING_TML: 'Berthing Tml.',
        FROM_DATE: 'From Date',
        TO_DATE: 'To Date',
        NO_OF_QUAY_CRANE: 'No. of Quay Crane',
        SHIFT_CODE_OPS: 'Shift Code(Ops)',
        GANG_ID: 'Gang Id',
        INTENSIVE_PERCENTAGE: 'Intensive%',
        OPERATION_START_DATE_TIME: 'Operation Start Date/Time',
        OPERATION_END_DATE_TIME: 'Operation End Date/Time',
        DELAY_REASON_CODE: 'Delay Reason Code',
        DELAY_REMARKS: 'Delay Remarks',
        LA_GRACE_MINS: 'Grace Minute Used',
        QUAY_CRANE_ID: 'Quay Crane ID',
        VOY_EQUIPMENT_HANDLING_CODE: 'Voy Equipment Handling Code',
        OWNER: 'owner',
        ETD: 'ETD',
        ETB: 'ETB',
        SO_NO: 'So No',
        WAGON_NO: 'Wagon No.',
        TRAIN_ATB: 'Train ATB',
        TRAIN_ATD: 'Train ATD',
        DRIVER_ID: 'Drive ID',
        DRIVER_NAME: 'Driver Name',
        GATE_OUT_DATE_TIME: 'Gate Out Date/Time',
        STORAGE_DAY_NUM: 'No. Of Storage',
        STORAGE_MODE: 'Storage Mode',
        CNTR_VESSEL_NAME: 'Cons/Vsl/Voy/Vessel Name',
        EXPIRY_DATE: 'Expiry Date',
        IN_YARD_DAY: 'In Yard Day',
        SECOND_TIER_FSP: '2nd Tier Fsp',
        ADJ_CHARGE_QTY: 'Qty Adj.',
        CALENDAR_ADJ_CHARGE_QTY: 'Calendar Adj. Charge Qty',
        MANUAL_ADJ_CHARGE_QTY: 'Manual Adj. Charge Qty',
        MANUAL_ADJ_CHARGE_UOM: 'Manual Adj. Charge UOM',
        CASH_OT_DAYS: 'Cash OT Days',
        OT_DAYS: 'Charging Qty',
        CHARGE_QTY_UOM: 'Charge UOM',
        CASH_AMOUNT: 'Cash Amount',
        CASH_RECEIPT_NO: 'Cash Receipt No.',
        WAIVER_NO: 'Waiver No.',
        FIRST_DISCHARGE_DATE: 'First Discharge Date/Time',
        LAST_DISCHARGE_DATE: 'Last Discharge Date/Time',
        FIRST_TIER_FSP: '1st Tier FSP',
        STORAGE_CHARGE_QTY: 'Storage Charge Qty',
        OPS_DATE: 'Ops Date',
        ALLIANCE: 'Alliance',
        MASTER: 'Master',
        CALCULATE_BY: 'Cal. By',
        QTY_20: '20\'',
        QTY_40: '40\'',
        QTY_45: '45\'',
        QTY_48: '48\'',
        QTY_53: '53\'',
        TEU_QTY: 'TEU Qty',
        FREE_POOL1: 'Free Pool 1',
        FREE_POOL2: 'Free Pool 2',
        SUPP_POOL1: 'Supp Pool 1',
        SUPP_POOL2: 'Supp Pool 2',
        EXCESS_POOL: 'Excess Pool',
        ACCOUNT_TML_REASON_CODE: 'Reason Code',
        RECEIVAL_CUTOFF_TIME: 'Receival Cutoff Time',
        ACCOUNT: 'Account',
        FIRST_LOADING_DATE_TIME: 'First Loading Date/Time',
        LAST_LOADING_DATE_TIME: 'Last Loading Date/Time',
        CHANGE_DATE_TIME: 'Change Date/Time',
        FIRST_DISCHARGE_DATE_TIME: 'First Discharge Date/Time',
        LAST_DISCHARGE_DATE_TIME: 'Last Discharge Date/Time',
        REEFER_HANDLING_TYPE: 'Ref Handling Type',
        CREATE_DATE_TIME: 'Create Date/time',
        REQUEST_DATE_TIME: 'Request Date/Time',
        PT_PLUG_ON_DATE_TIME: 'Power Plug-On Date/time',
        PT_PLUG_OFF_DATE_TIME: 'Power Plug-Off Date/time',
        PC_PLUG_ON_DATE_TIME: 'Power Cons. Start Date/time',
        PC_PLUG_OFF_DATE_TIME: 'Power Cons. End Date/time',
        TELEX_NO: 'Telex No.',
        AGENT_CODE: 'Agent Code',
        VESSEL_TYPE: 'Vessel Type',
        MARSHALLING_TYPE: 'Marshalling Type',
        CARGO_TYPE: 'Cargo Type',
        PALLETIZED_INDICATOR: 'Palletized Indicator',
        OTHER_ETB: 'Other Etb',
        OTHER_OPERATING_TML: 'Other Operating Tml',
        VESSEL_INFO: 'Vessel Info',
        OTHER_SERVICE_CODE: 'Other Service Code',
        OTHER_CHARGE_ON_COMPANY: 'Other Charge On Company',
        OTHER_SPECIAL_IND: 'Other Special Ind',
        OTHER_DIRECT_IND: 'Other Direct Ind',
        OTHER_ETD: 'Other Etd',
        OTHER_FIRST_DISCHARG_EDATE_TIME: 'Other First Discharge Date/time',
        OTHER_LAST_DISCHARGE_DATE_TIME: 'Other Last Discharge Datet/ime',
        MARSHALLING_GROUND_DATE_TIME: 'Marshalling Ground Date/time',
        CHARGE_TO_ACCOUNT_CODE: 'Charge To Account Code',
        ASSIGN_SEQ_NO: 'Assign Seq. No.',
        FROM_SEQ: 'From Seq.',
        TO_SEQ: 'To Seq.',
        YOUR_REF: 'Your Ref.',
        OUR_REF: 'Our Ref.',
        PO_NO: 'PO No.',
        NEW_SEQ: '-NEW-',
	    ACCOUNT_TERMINAL: 'Account Terminal',
        ASSIGN_ONE_OFF_RATE: 'Assign One-Off Rate',
        UPT_ABSOLUTELY_COMMON_AC: 'Update Absolutely Common Account',
        COPY_COMMON_AC: 'Copy Common Account',
        AJDUSTMENT: 'Adjustment',
        COMMON_ACCOUNT_BUT: 'Common Account',
        ASSING_COMMON_ACCOUNT_BUT: "Assign Common Account",
        REVERSE_COMMON_ACCOUNT_BUT: "Reverse Common Account",
        REDIRECT_COMMON_ACCOUNT_BUT: "Re-direct Common Account",
        UPDATE_100_COMMON_ACCOUNT_BUT: "Update 100% Common Account",
        COPY_COMMON_ACCOUNT_TO_BUT: "Copy Cm A/C To",
        UPDATE_CNTR_STATUS_BUT: "Update Status",

        CHARGE_DTL_STATE_LEGEND: 'State Legend',
        STATE_RED: 'SG/SA/E',
        STATE_GREY: 'I/DI/T/MT',
        STATE_GREEN: 'R/F',
        STATE_BLUE: 'Others',

        TOTAL_IN_TEU: "Total In TEU",
        TOTAL_IN_ITEM: "Total In Item",
        EXCESS_POOL_IN_TEU: 'Excess Pool In TEU',
        EXCESS_POOL_IN_ITEM: 'Excess Pool In Item',
        POOL_UOM: 'Pool UOM',
        CHARGING_FROM_DATE: 'Charging From Date',
        CHARGING_TO_DATE: 'Charging To Date',
    },
    Vessel: {
        VSL_VOY_LIST: 'Vsl/Voy List',
        CONSORTIUM_CODE: 'Consortium Code',
        VESSEL_CODE: 'Vessel Code',
        VOYAGE_CODE: 'Voyage Code',
        HANDLING_TERMINAL: 'Handling Tml.',
        VESSEL_NAME: 'Vessel Name',
        SOA: 'SOA',
        LOA: 'LOA',
        VESSEL_TYPE: 'Vessel Type',
        ETD: 'ETD',
        ARR_DATE_TIME: 'Arr Date/Time',
        DEP_DATE_TIME: 'Dep Date/Time',
        SERVICE_CODE: 'Service Code',
        FINALIZED_IND: "F/UF"
    }
}