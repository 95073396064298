import { AbstractEntity } from "domain/entity/Common/AbstractEntity";
import _ from "lodash";
import moment from "moment";
import { DECIMAL_1, DECIMAL_2, DECIMAL_3, DECIMAL_4, formatNumber } from "presentation/utils/numberUtil";
import { convertDateToDateStr, convertDateToDateTimeStr, convertDateToLocal, convertDateToTimeStr } from "presentation/utils/timeUtil";

export const calculateStateColor = (fieldName:string, row:any) => {
    let result:{[key:string]:string} = {};
    if (fieldName && row) {
        const className = fieldName + "Class";
        const fieldValue:string = (row[fieldName] as unknown as string);
        result[fieldName] = fieldValue;

        if (fieldValue === "SG" || fieldValue === "SA" || fieldValue === "E" || fieldValue === "FAIL" || fieldValue === "FAILED" || fieldValue==="Pending") {
            result[className] = "red";
        } else if (fieldValue === "I" || fieldValue === "DI" || fieldValue === "T" || fieldValue === "MT" 
            || fieldValue === "CFM" || fieldValue === "INV" || fieldValue === "TML" || fieldValue === "COMPLETED"
            || fieldValue === "Sent") {
            result[className] = "grey";
        } else if (fieldValue === "R" || fieldValue === "F" || fieldValue === "NEW" || fieldValue === "NIL" || "STD") {
            result[className] = "green";
        } else {
            result[className] = "blue";
        }
    }
    return result;
}

export const defineColumn = (col:any, index:number, dateFieldList?:string[], dateTimeFieldList?:string[], 
    timeFieldList?:string[], cellRenderers?:{[key:string]:(params:{[key:string]:string}, fieldName:string) => {}}, numberFieldList?:string[]) => {
    if (Object.keys(cellRenderers??{}).includes(col.field)) {
        return {
            ...col, 
            header: col.headerName, 
            id: index + 1, 
            dataType: 'text',
            cellRenderer: cellRenderers && cellRenderers[col.field] ? (params:{[key:string]:string}, fileName:string) => cellRenderers[col.field](params, col.field) : {}
        }
    } else if (col.dataType === "date") {
        dateFieldList?.push(col.field);
        return {
            ...col, 
            header: col.headerName,
            // field: col.field + "Date",
            fieldExtend: col.field + "Date",
            id: index + 1,
        }
    } else if (col.dataType === "dateTime") {
        dateTimeFieldList?.push(col.field);
        return {
            ...col, 
            header: col.headerName, 
            // field: col.field + "Datetime",
            fieldExtend: col.field + "Datetime",
            id: index + 1,
        }
    } else if (col.dataType === "time") {
        timeFieldList?.push(col.field);
        return {
            ...col, 
            header: col.headerName,
            // field: col.field + "Time", 
            fieldExtend: col.field + "Time", 
            id: index + 1,
            dataType: 'dateTime',
        }
    } else if (col.dataType === DECIMAL_1 || col.dataType === DECIMAL_2 || col.dataType === DECIMAL_3 || col.dataType === DECIMAL_4) {
        let dataTypeStr:string = col.dataType.toString();
        let dataTypeArr = dataTypeStr.split("_");        
        numberFieldList?.push(col.field + "_" + dataTypeArr[1]);
        
        return {
            ...col, 
            header: col.headerName,
            field: col.field + "Number", 
            fieldExtend: col.field + "Number", 
            id: index + 1,
            dataType: 'number'
        }
    } else {
        return {
            ...col, 
            header: col.headerName, 
            id: index + 1, 
            dataType: 'text'
        }
    }
}

export function transferRowDataInternal<T extends AbstractEntity>(data:T[], dateFieldList?:string[], dateTimeFieldList?:string[], 
    timeFieldList?:string[], externalFnctions?:{[fieldName:string]:(fieldName:string, row:any)=>{}}, numberFieldList?:string[]):T[] {
    if (data && _.isArray(data)) {
        return data?.map(row => {
            let basicRow:T = {
                ...row,                
            };
            _.forEach(
                _.toPairs(externalFnctions), ([key, fun]) => {
                    basicRow = {
                        ...basicRow,
                        ...fun(key, basicRow),
                    }
                }
            );
            dateFieldList?.map(field => {
                basicRow = {
                    ...basicRow,
                    [field + "Date"]: basicRow[field] ? convertDateToDateStr(convertDateToLocal(moment((basicRow[field] as unknown as Date)))): null,
                }
                return basicRow;
            });
            dateTimeFieldList?.map(field => {
                basicRow = {
                    ...basicRow,
                    [field + "Datetime"]: basicRow[field] ? convertDateToDateTimeStr(convertDateToLocal(moment((basicRow[field] as unknown as Date)))): null,
                }
                return basicRow;
            });
            timeFieldList?.map(field => {
                basicRow = {
                    ...basicRow,
                    [field + "Time"]: basicRow[field] ? convertDateToTimeStr(convertDateToLocal(moment((basicRow[field] as unknown as Date)))): null,
                }
                return basicRow;
            });
            numberFieldList?.map(field => {
                let fieldArr = field.split("_");
                let fieldName = fieldArr[0];
                let decimalPlaces = fieldArr[1] as unknown as number;

                basicRow = {
                    ...basicRow,
                    [fieldName + "Number"]: basicRow[fieldName] ? formatNumber(basicRow[fieldName] as unknown as number, decimalPlaces): null,
                }
                return basicRow;
            });

            return basicRow;
        });
    }
    return [];
}


