
import { ChargeConfirmedEntity } from "domain/entity/ChargeConfirmed/ChargeConfirmedEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ChargeConfirmedRepository } from "./ChargeConfirmedRepo";
export const ChargeConfirmedRepoImpl = (): ChargeConfirmedRepository => {
    const url = '/v1/chargeConfirmedList';
    const detailUrl = '/v1/chargeConfirmedListDetails';

    /**
     * Get all confirmed normal charges,manual charges and credit note charges
     * 
     * @returns 
     */
    const getAllChargeConfirmedList = async() : Promise<ChargeConfirmedEntity[]> => {
        return axiosGetData(chargeAxiosInstance, url, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    /**
     * Search the charge confirmed list by username
     * 
     * @param username 
     * @returns 
     */
    const getChargeConfrimedListByUser = async(username: string): Promise<ChargeConfirmedEntity[]> => {
        return axiosGetData(chargeAxiosInstance, `${url}/${username}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    } 
    
    /**
     * Submit charge(s) to generate invoice
     * 
     * @param chargeConfirmedList 
     * @returns 
     */
    const submitToGenDraftInv = async(chargeConfirmedList: ChargeConfirmedEntity[]): Promise<ResponseEntity> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${url}`, chargeConfirmedList) as ResponseEntity;
        } catch (error) {
            throw error;
        }
        return res;
    }

    /**
     * Search the charge details for the confirmed charge
     * 
     * @param chargeConfirmedList 
     * @returns 
     */
    const getChargeConfirmedListDetails = async(chargeConfirmedList: ChargeConfirmedEntity[]): Promise<ResponseEntity> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${detailUrl}`, chargeConfirmedList) as ResponseEntity;
        } catch (error) {
            throw error;
        }
        return res;
    }
    

    return {
        getAllChargeConfirmedList: getAllChargeConfirmedList,
        getChargeConfrimedListByUser: getChargeConfrimedListByUser,
        submitToGenDraftInv: submitToGenDraftInv,
        getChargeConfirmedListDetails: getChargeConfirmedListDetails,
    }
}