import { ChargeConfirmedRepoImpl } from "domain/repository/ChargeConfirmed/ChargeConfirmedRepoImpl";
import { DynamicTableRepoImpl } from "domain/repository/Common/DynamicTableRepoImpl";
import { useChargeConfirmedTracked } from "presentation/store/ChargeConfirmed/ChargeConfirmedProvider";
import { ChargeConfirmedVM } from "presentation/viewModel/ChargeConfirmed/ChargeConfirmedVM";
import { useMemo } from "react";

export const useChargeConfirmedVM = () => {
    const [, setChargeConfirmedState] = useChargeConfirmedTracked();
    const chargeConfirmedVM = useMemo(() =>
        ChargeConfirmedVM({
            dispatch: [setChargeConfirmedState],
            dynamicTableRepo: DynamicTableRepoImpl(),
            chargeConfirmedRepo: ChargeConfirmedRepoImpl(),
        }), [setChargeConfirmedState])

    return chargeConfirmedVM
}