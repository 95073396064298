import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ChargeConfirmedConstant } from "./ChargeConfirmedConstant";

const CHG_CONF_CONSTANT = ChargeConfirmedConstant.Header;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];

export const INITIAL_CHG_CONF_COL_DEF: any[] = [
    {
        headerName: CHG_CONF_CONSTANT.BILL_TO,
        field: 'billToCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: CHG_CONF_CONSTANT.HANDLING_TML,
        field: 'handlingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHG_CONF_CONSTANT.CUST_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHG_CONF_CONSTANT.CUST_NAME1,
        field: 'customerName1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHG_CONF_CONSTANT.CUST_NAME2,
        field: 'customerName2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHG_CONF_CONSTANT.SERV_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CHG_CONF_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "date"
    },
    {
        headerName: CHG_CONF_CONSTANT.BILL_CYCLE,
        field: 'billingCycle',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHG_CONF_CONSTANT.BILL_CYCLE_CODE,
        field: 'billingCycleCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHG_CONF_CONSTANT.OPS_FROM,
        field: 'opsDateFrom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "date"
    },
    {
        headerName: CHG_CONF_CONSTANT.OPS_TO,
        field: 'opsDateTo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "date"
    },
    {
        headerName: CHG_CONF_CONSTANT.CHG_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: CHG_CONF_CONSTANT.CO,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHG_CONF_CONSTANT.VSL,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHG_CONF_CONSTANT.VOY,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHG_CONF_CONSTANT.ETD,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "date"
    },
    {
        headerName: CHG_CONF_CONSTANT.END_VISIT_IND,
        field: 'endVisitInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHG_CONF_CONSTANT.END_VISIT_DATE,
        field: 'endVisitDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "date"
    },
    {
        headerName: CHG_CONF_CONSTANT.FULL_EMPTY,
        field: 'fullEmptyInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHG_CONF_CONSTANT.DOC_TYPE,
        field: 'docType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHG_CONF_CONSTANT.CONF_BY,
        field: 'confirmedBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHG_CONF_CONSTANT.CONF_DATE,
        field: 'confirmedDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
        dataType: "date"
    },
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}