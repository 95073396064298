export const manChgDtl2dpNumberFieldList: string[] = [
    'chargeAmount',  
    'additionalChargeQty',  
    'rate',  
    'chargeSharePercentage',
    'chargePercentage',  
    'vatPercentage', 
    'chargeQty',
    'qty',
];

export const manChgDtl4dpNumberFieldList: string[] = [
    'individualChargeAmount',
];

export const manChgDtlPositiveIntegerNumberFieldList: string[] = [
    'vatDays',  
    'vatDaysCredit',  
    'vatDaysDebit',
    'overWidth',
    'overHeight',
    'overLength',
];

export const manChgHdrRequiredFieldList: string[] = [
    'opsDate', 
    'billToCompany',
    'operatingTml',
    'chargeType',  
];

export const manChgDtlRequiredFieldList: string[] = [
    'tariffType', 
    'tariffCode',
    'chargeOnCompany',
    'chargeQty',  
    'vatPercentage',  
    'individualChargeQtyUom',  
    'vatOneOffTaxCode',
    'vatTarType',  
    'vatTarCode',  
    'vatArticleStatementCode',   
    'vatDays',  
    'vatDaysCredit',  
    'vatDaysDebit',  
    'paymentReferenceDate',  
    'paymentReferenceDateCredit',  
    'paymentReferenceDateDebit', 
];



export const ManualChargeConstant = {    
    Header: {
        MANUAL_CHARGE: 'Manual Charge',
        REF_NO: 'Ref. No.',
        STATE: 'State',
        CHARGE_TYPE: 'Charge Type',
        BILL_TO_COMPANY: 'Bill to Company',
        CO_VSL_VOY: 'Co/Vsl/Voy',
        CONS_CODE: 'Consortium Code',
        VSL_CODE: 'Vessel Code',
        VOY_CODE: 'Voyage Code',
        VSL_NAME: 'Vessel Name',
        INV_CR_NO: 'IN/CN Note No.',
        OPS_DATE: 'Ops Date',
        OPS_DATE_RANGE: 'Ops Date Range',
        CREATED_DATE: 'Created Date',
        CREATED_DATE_RANGE: 'Created Date Range',
        CREATED_BY: 'Created By',
        MODIFIED_DATE: 'Modified Date',
        MODIFIED_BY: 'Modified By',
        CNTR_NO: 'Container No.',
        SERVICE: 'Service',
        ARR_DATETIME: 'Arr Date/Time',
        DEP_DATETIME: 'Dep Date/Time',
        YOUR_REF: 'Your Ref',
        OUR_REF: 'Our Ref',
        PO_NO: 'P.O. No.',
        LOA: 'LOA',
        OPERATING_TML: 'Operating Tml',
        SOA: 'SOA',
        SL_VSL_CODE: 'S/L Vsl Code',
        SL_IB_VOY_CODE: 'S/L IB Vsl Code',
        SL_OB_VOY_CODE: 'S/L OB Vsl Code',
        INV_NO: 'Invoice No.',
        CREDIT_NOTE_NO: 'Credit Note No.',
        DRAFT_INV_NO: 'Draft Invoice No.',
        DRAFT_CREDIT_NOTE_NO: 'Draft Credit Note No.',
        HDR_DESC1: 'Description 1',
        HDR_DESC2: 'Description 2',
    },
    Detail:{
        MAN_CHG_DTL: 'Manual Charge Detail',
        TAR_TYPE: 'Tar Type',
        TAR_CODE: 'Tar Code',
        TAR_DESC: 'Tariff Code Desc',
        CHARGE_ON: 'Charge-on Company',
        CHARGE_QTY: 'Charging Qty',
        CHARGE_STO_QTY: 'Charging Storage Qty',
        UOM: 'Qty Uom',
        LOG_NO: 'Log No.',
        ADDITION_QTY: 'Addition Qty',
        ONE_OFF_RATE: 'One-off Rate',
        COMMON_ACC: 'Cm AC%',
        SURCHARGE: 'Surcharge',
        SHIFT_CODE: 'Shift Code',
        TAX_TAR_TYPE: 'Tax Tariff Type',
        TAX_TAR_CODE: 'Tax Tariff Code',
        TAX_TAR_DESC: 'Tax Tariff Code Desc',
        TAX_PERCENTAGE: 'Tax%',
        TX_TYPE: 'Transaction Type',
        ARTICLE_STATEMENT: 'Article Statement',
        TAX_CODE: 'Tax Code',
        ARTICLE_STATEMENT_CODE: 'Article Statement Code',
        CHG_FROM_DATE: 'Charging From Date',
        CHG_TO_DATE: 'Charging To Date',
        TAX_PAYMENT_TERM_IN: 'Tax Payment Term(Invoice)',
        TAX_PAYMENT_TERM_CR: 'Tax Payment Term(Credit)',
        TAX_PAYMENT_TERM_DEBIT: 'Tax Payment Term(Debit)',
        TAX_PAYMENT_REF_TERM_IN: 'Tax Payment Reference Date(Invoice)',
        TAX_PAYMENT_REF_TERM_CR: 'Tax Payment Reference Date(Credit)',
        TAX_PAYMENT_REF_TERM_DEBIT: 'Tax Payment Reference Date(Debit)',
        REF_NO_ADJ_FROM: 'Ref No Adj From',
        ADJ_TYPE: 'Adj. Type',
        CHARGE_IND: 'Charge Ind.',
        FW_CODE: 'Forwarder Code',
        EMPTY_IND: 'Empty Indicator',
        TS_STATUS: 'Transportation Status',
        OP_TYPE: 'Operation Type',
        REASON_CODE: 'Reason Code',
        EX_MSG: 'Exception Msg',
        GET_SHIFT: 'Get Shift',
        CNTR_DTL: 'Container Detail',
        UPLOAD: 'Upload',
        GET_UOM: 'Get Uom',
        PARTNER: 'Partner Code',

    },
    CntrInfo: {
        CNTR_NO: 'Container No.',
        CNTR_SIZE: 'Container Size',
        CNTR_TYPE: 'Container Type',
        CNTR_STATUS: 'Container Status',
        OVER_LENGTH: 'Over Length',
        OVER_HEIGTH: 'Over Height',
        OVER_WIDTH: 'Over Width',
        IMCO_CODE: 'IMCO Code',
        MODALITY_ARR: 'Modality of Arrival',
        MODALITY_DEP: 'Modality of Departure',
        IN_DATE_TIME: 'In Date Time',
        OUT_DATE_TIME: 'Out Date Time',
        CHARGE_QTY: 'Charge Qty',
        SPECIAL_IND: 'Special Ind.',
        SP_HANDLING_IND: 'SP Handling Ind.',
        SPECIAL_HANDLING_CODE: 'Special Handling Code',
        SPECIAL_HANDLING_IND_D1: 'Special Handling Ind.(D1)',
        SPECIAL_HANDLING_CODE_D1: 'Special Handling Code(D1)'
    }
}